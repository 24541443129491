jQuery( document ).ready(function($) { 
  /* SlimMenu Prep */
  $('#navigation').slimmenu({
    resizeWidth: '992',
    collapserTitle: '',
    animSpeed: 0,
    easingEffect: null,
    indentChildren: false,
    childrenIndenter: '&nbsp;',
    expandIcon: '',
    collapseIcon: ''
  });
  // run test on initial page load
  checkSize();

  // Sets up images from data and video and resets on modal exit
  $('.modal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var modal = $(this);
    modal.css('display','flex');

    if(modal.is('#previewModal')) {
      var image = button.data('img');
      modal.find('img').attr('src',image);
    } else {
      var video = button.data('video');
      modal.find('iframe').attr('src',video);
    }
  });
  $('.modal').on('hide.bs.modal', function (event) {
    var modal = $(this);
    if(modal.is('#previewModal')) {
      modal.find('img').attr('src','');
    } else {
      modal.find('iframe').attr('src','');
    }
  });
});

// Shifts menu to the left when it goes off screen
$(function () {
  $(".slimmenu:not(.collapsed) > li.has-submenu").on('mouseenter', function (e) {
    var elm = $('>ul', this);
    var off = elm.offset();
    var l = off.left;
    var r = off.right;
    var w = elm.outerWidth();
    var docW = $("body").width();
    var isEntirelyVisible = (l + w <= docW);
    var fix = (l + w - docW);

    if (!isEntirelyVisible) {
        $(this).children('ul').css('left', -fix - 16);
    }
  });
});

// Moves items from topbar into mobile menu upon collapsing
function checkSize(){
  if ($(".slimmenu").hasClass('collapsed')){
    
    var listItem = $('.source-list>li');

    listItem.each(function(){
      var listSource = $(this).parent();
      var listId = listSource.attr("id");

      // clone the item to a variable
      var cloneItem = $(this).clone();
      $(this).remove();

      // append cloned item to selected-list
      cloneItem.data("listId", listId).appendTo(".selected-list");
    });
  } else {

    var SlistItem = $('.selected-list>li.si');

    SlistItem.each(function(){
      var SlistId = $(this).data("listId"); 

      // clone the item to a variable
      var ScloneItem = $(this).clone();
      $(this).remove();

      // append cloned item to source-list
      ScloneItem.appendTo("#" + SlistId);
    });
  }
}

$('#cartList .card-header').click(function() {
  $('body').toggleClass('cart-open');
});

/* Search Box functionality */
// Submit on keypress 'Enter'
$('#searchinput').keypress(function(e) {
  if (e.which == 13) {
    window.location.href="/Search?q=" + $(this).val();
  }
});

// Submit on button click
$('#searchinput').siblings('.search-submit').click(function(e) {
  e.preventDefault(e);
  window.location.href="/Search?q=" + $('#searchinput').val();
});

// Shift in DOM below large
if ($(window).width() < 992) {
  var menu = $('.bottombar').children().first();
  $('.bottombar').children('.searchbar').insertBefore(menu);
}

/* End Search Box functionality */

/* Carousel Accessibility functionality */
$('#myCarousel').on('slide.bs.carousel', function(){
  $('li').attr('aria-selected','false').attr('tabindex','-1');
}).on('slid.bs.carousel', function(){
  $('li.active').attr('aria-selected','true').attr('tabindex','0').focus();
});

/* End Carousel Accessibility functionality */

// Anchor Link Functionality
$('.anchorlink').click(function(event) {
  // On-page links
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      $('html, body').animate({scrollTop: target.offset().top - 150}, 500, function() {
        // Callback after animation
        // Must change focus!
        var $target = $(target);
        $target.focus();
        if ($target.is(":focus")) { // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        }
      });
    }
  }
});
